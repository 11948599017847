import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import About from "./about"

function IndexPage() {
  return (
    <About />
  )
}

export default IndexPage
